.ovy-a {
  display: flex;
  position: relative;
}
.ovy-a .tacticsRule_left {
  width: 150px;
}
.ovy-a .tacticsRule_left .shell {
  position: fixed;
  line-height: 30px;
  overflow-y: scroll;
  height: 81%;
  width: 150px;
}
.ovy-a .tacticsRule_left .shell .shellItem {
  user-select: none;
  background: #fafafa;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-right: 0.675rem;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ecf5ff;
  transition: 0.5s;
}
.ovy-a .tacticsRule_left .shell .shellItem.shellItem-active {
  border: 1px solid #2878ff;
}
.ovy-a .tacticsRule_left .shell .shellItem.shellItem-active .shellTitle {
  background: #2878ff;
  color: #fff;
}
.ovy-a .tacticsRule_left .shell .shellItem.shellItem-active p {
  color: #2878ff;
}
.ovy-a .tacticsRule_left .shell .shellItem .shellTitle {
  font-size: 16px;
  line-height: 1.2;
  padding: 6px;
  margin-bottom: 8px;
  transition: 0.5s;
  background: #ecf5ff;
  color: #2878ff;
  text-align: center;
}
.ovy-a .tacticsRule_left .shell .shellItem p {
  transition: 0.5s;
  font-size: 14px;
  line-height: 1.2;
  padding: 2px 6px;
}
.ovy-a .tacticsRule_left .shell .shellItem:hover {
  border: 1px solid #2878ff;
}
.ovy-a .tacticsRule_left .shell .shellItem:hover .shellTitle {
  background: #2878ff;
  color: #fff;
}
.ovy-a .tacticsRule_left .shell .shellItem:hover p {
  color: #2878ff;
}
.ovy-a .tacticsRule_right {
  flex: 1;
  padding-left: 20px;
  width: 80%;
}
.ovy-a .tacticsRule_right .el-form {
  width: 500px;
}
.ovy-a .tacticsRule_right .el-form .jbxx /deep/ .el-form-item__label {
  font-size: 16px;
  font-weight: 600;
}
.ovy-a .operateDiv {
  position: absolute;
  top: 0;
  right: 20px;
}
 /deep/ .el-table__fixed-right,
 /deep/ .el-table .el-table__fixed {
  height: 100% !important;
}
.questionBox > div {
  margin-bottom: 15px;
}
.questionBox > div:last-child {
  margin-bottom: 0;
}
.questionInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
}
.questionInner:hover {
  background: #ecf5ff;
}
.questionInnerLeft {
  position: relative;
  width: 100%;
}
.questionTitle {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.questionType {
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}
.questionOption {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
}
.questionOption span {
  width: 25%;
  padding-right: 8px;
  margin-bottom: 10px;
}
.questionAnswer {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
}
.questionAnswer > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.questionAnswer .questionAnswerS {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(133, 206, 97, 0.3);
}
.questionAnswer .questionAnswerS:last-child {
  border: 0;
}
.questionAnalysis {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;
}
.questionAnalysis > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #e6a23c;
  background: #fdf6ec;
  border-color: #f5dab1;
}
.questionInnerRight {
  min-width: 156px;
  max-width: 156px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
.questionInnerRight > div:nth-child(1) {
  flex-grow: 1;
}
.questionInnerRight > div:nth-child(2) {
  flex-shrink: 0;
}
.showChildrenQuestion {
  cursor: pointer;
  color: #2878ff;
}
.childrenQuestionItem {
  position: relative;
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  margin-right: -169px;
}
.childrenQuestionItem .questionTitle,
.childrenQuestionItem .questionAnswer,
.childrenQuestionItem .questionAnalysis,
.childrenQuestionItem .questionOption {
  width: calc(100% - 149px);
}
.childrenQuestionItem .mt16 {
  position: absolute;
  width: 149px;
  top: 0;
  right: 0;
}
/*试卷样式*/
.paperBox {
  max-height: calc(100vh - 255px);
  overflow-y: scroll;
}
.paperBox .paperItem {
  padding: 20px 0;
}
.paperBox .paperItem .paperHeader {
  padding: 6px 12px;
  background: #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.paperBox .paperItem .paperHeader > div {
  margin-left: 10px;
  min-width: 92px;
}
.paperBox /deep/ .el-input__suffix {
  display: flex;
  align-items: center;
}
.paperBox /deep/ .el-input__inner {
  width: 320px;
  padding-right: 48px;
}
.paperBox .questionInnerRight /deep/ .el-input--small {
  width: 100%;
}
.paperBox .questionInnerRight /deep/ .el-input__inner {
  width: 100%;
  padding-right: 15px;
}
.mt16 {
  margin-top: 16px;
  margin-left: 8px;
}
.mt16 /deep/ .el-input__inner {
  width: 88px!important;
  padding-right: 15px!important;
}
.hh {
  word-break: break-all;
  word-wrap: break-word;
}
 /deep/ .operationControl .el-input .el-input__suffix {
  display: flex;
  align-items: center;
}
 /deep/ .operationControl .el-input input {
  width: 100%;
}
 /deep/ .el-button--danger:focus,
.el-button--danger:hover {
  background: #F56C6C;
  border-color: #F56C6C;
}
.addBigQuestion /deep/ .el-input .el-input__count {
  display: inline-block;
}
.addBigQuestion /deep/ .el-input .el-input__count .el-input__count-inner {
  width: auto!important;
  min-width: auto!important;
  padding: 0;
}
.addBigQuestion /deep/ .el-input .el-input__suffix-inner {
  min-width: auto!important;
  padding: 0;
}
.addBigQuestion /deep/ .el-input input {
  padding-right: 55px;
}
